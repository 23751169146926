<template>
  <div class="lms-data-table-search">
    <div v-if="active" class="input">
      <v-text-field
        append-icon="mdi-close"
        outlined
        name="search"
        v-model="term"
        :label="$t('labels.search')"
        hide-details
        @click:append="toggle"
        ref="textField"
      />
    </div>
    <div v-else>
      <div class="open" @click="toggle">
        <v-icon>mdi-magnify</v-icon>
      </div>
    </div>
  </div>
</template>

<script lang="js">
export default {
	data: () => ({
		active: false,
		term: ''
	}),
	watch: {
		term(value) {
			this.$emit('input', value)
		}
	},
	methods: {
		toggle(){
			this.active = !this.active
			this.term = ''

      if(this.active){
        this.$nextTick(()=>{
          this.$refs.textField.focus()
        })
      }
		}
	}
}
</script>

<style lang="scss">
@import "../../assets/scss/base/variables";

.lms-data-table-search {
  display: flex;
  justify-content: end;

  .open {
    padding: 6px 0 6px 6px;
    cursor: pointer;

    .v-icon:hover {
      color: $primary;
    }
  }
  .input {
    display: flex;
    width: 100%;
    max-width: 300px;
  }
}
</style>
