<template>
  <div class="lms-data-table-filter">
    <v-select v-model="selected" :items="items" hide-details />
  </div>
</template>

<script lang="js">
export default {
	props: {
		items: Array
	},
	data: () => ({
		selected: null
	}),
	watch: {
		selected(value) {
			this.$emit('input', value)
		}
	},
	created() {
		this.selected = this.items[0].value
	},
}
</script>

<style lang="scss">
@import "../../assets/scss/base/variables";

.lms-data-table-filter {
  background-color: $primary;

  .v-text-field {
    margin-top: 0px;
    padding-top: 2px;
  }

  .v-select__selection {
    color: $global-bg !important;
    margin: 0 0 0 12px;
  }

  .v-icon {
    color: $global-bg !important;
  }

  .v-input__slot:before {
    border: none !important;
  }

  .v-input__append-inner {
    margin-right: 8px;
  }
}
</style>
