<template>
  <div>
    <f-page-header :title="title" :prevLinks="prevLinks" />

    <f-country-list />
  </div>
</template>

<script>
import FCountryList from '../../../../components/views/settings/data/CountryList.vue';

export default {
  components: {
    FCountryList,
  },
  computed: {
    prevLinks() {
      return [
        {
          title: this.$t('labels.settings'),
          route: {
            name: 'settings',
          },
        },
      ];
    },
    title() {
      return this.$t('labels.countries');
    },
  },
};
</script>
