<template>
  <f-container topBottom>
    <lms-card>
      <lms-card-title>
        <template #icon>
          <v-icon>mdi-table-of-contents</v-icon>
        </template>

        {{ $tt.capitalize($t('labels.countries')) }}

        <template #actions>
          <lms-data-table-search v-model="filters.search" />
        </template>
      </lms-card-title>

      <lms-data-table-filter v-model="filters.status" :items="filterOptions" />

      <v-data-table
        class="elevation-0"
        :class="{ tblloadingeffect: isLoading }"
        :footer-props="defaultFooterProps"
        :headers="headers"
        :items="countries"
        :options.sync="options"
        :server-items-length="countriesCount"
        @changePage="page => onChangePage(page)"
        @changeItemsPerPage="limit => onChangeItemsPerPage(limit)"
      >
        <template v-slot:[`item.name`]="{ item }">
          <div @click="edit(item._id)">
            {{ item.name[$i18n.locale] }}
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <lms-data-table-btn @click="addDistributor(item._id)">
            <v-icon>mdi-plus</v-icon>
          </lms-data-table-btn>
          <lms-data-table-btn @click="edit(item._id)">
            <v-icon>mdi-pencil</v-icon>
          </lms-data-table-btn>
          <lms-data-table-btn
            @click="remove(item._id)"
            v-if="$store.state.system.superAdmin"
          >
            <v-icon>mdi-delete</v-icon>
          </lms-data-table-btn>
        </template>
      </v-data-table>
    </lms-card>
  </f-container>
</template>

<script>
import { defaultFooterProps } from '../../../../helpers/data_table';
import LmsDataTableFilter from '../../../shared/DataTableFilter.vue';
import LmsDataTableSearch from '../../../shared/DataTableSearch.vue';

export default {
  components: {
    LmsDataTableFilter,
    LmsDataTableSearch,
  },
  data() {
    return {
      defaultFooterProps,
      filters: {
        status: 'enabled',
        search: '',
        page: 1,
        limit: defaultFooterProps['items-per-page-options'][0],
      },
      searchTimeout: null,
      options: {},
    };
  },
  computed: {
    countries() {
      return this.$store.state.setting.country.list;
    },
    countriesCount() {
      return this.$store.state.setting.country.listCount;
    },
    filterOptions() {
      return [
        {
          text: this.$t('labels.enableds'),
          value: 'enabled',
        },
        {
          text: this.$t('labels.disableds'),
          value: 'disabled',
        },
        {
          text: this.$t('labels.all'),
          value: 'all',
        },
      ];
    },
    headers() {
      return [
        {
          text: this.$tt.capitalize(this.$t('reports.student.name')),
          align: 'start',
          value: 'name',
        },
        {
          text: this.$tt.capitalize(this.$t('labels.actions')),
          align: 'end',
          value: 'actions',
        },
      ];
    },
  },
  watch: {
    'filters.search': {
      handler: function() {
        clearTimeout(this.intervalSearch);
        const get = this.get;
        this.intervalSearch = setTimeout(() => {
          // this.$emit("search", value);
          get();
        }, 500);
      },
    },
    options: {
      handler: function(values, old) {
        if (values.page !== old.page) {
          this.filters.page = values.page;
          this.get();
        }
        if (values.itemsPerPage !== old.itemsPerPage) {
          this.filters.limit = values.itemsPerPage;
          this.get();
        }
      },
      deep: true,
    },
    'filters.status': function() {
      this.get();
    },
  },
  created() {
    // this.get();
  },
  methods: {
    edit(id) {
      this.$router.push({
        name: 'settings-country-edit',
        params: { id },
      });
    },
    addDistributor(id) {
      this.$router.push({
        name: 'settings-distributor-create',
        params: { countryid: id },
      });
    },
    get() {
      this.$store.dispatch('setting/listCountries', this.filters);
    },
    remove(itemID) {
      // console.log(itemID, 'delete');
    },
    onChangePage(page) {
      // console.log('page', page);
    },
  },
};
</script>
